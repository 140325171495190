import { MAX_ACTIVE_ORDERS, MAX_NUMBER_SUPPLY_LISTING_IMAGES } from './consts';

export const displayStrings = {
	pages: {
		root: {
			title: 'Surplus | Repurposed Building Materials Near Me',
			description:
				'Surplus streamlines buying and selling unused building materials, allowing you to reduce construction waste and save money. Like Habitat for Humanity ReStore or OFFLOADIT but with contractors near me. Wood, concrete, stone, steel, brick, and more.',
			subheader: 'A Network of Repurposed Building Materials',
			ctaButton: 'Enter',
			aboutButton: 'About',
			learnMore: 'Learn more about Surplus',
		},
		login: {
			title: 'Surplus | Login',
			description:
				'Surplus streamlines buying and selling unused building materials, allowing you to reduce construction waste and save money. Like Habitat for Humanity ReStore or OFFLOADIT but with contractors near me. Wood, concrete, stone, steel, brick, and more.',
			header: 'Sign in',
			labels: {
				email: 'Email',
				password: 'Password',
				loginWithGoogle: 'Sign in with Google',
				createAccount: "Don't have an account?",
				createAccountLink: 'Create account',
			},
			placeholders: {
				email: 'you@me.com',
				password: 'password',
			},
			errors: {
				emailDoesNotExist: 'Incorrect email or password',
				invalidPassword: 'Incorrect email or password',
			},
		},
		about: {
			title: 'Surplus | About',
			description:
				'Surplus streamlines buying and selling unused building materials, allowing you to reduce construction waste and save money. Like Habitat for Humanity ReStore or OFFLOADIT but with contractors near me. Wood, concrete, stone, steel, brick, and more.',
			header: 'What is Surplus?',
			newsHeader: 'Surplus in the news',
			bodyText:
				"Surplus is a consolidated logistics platform revolutionizing the flows of surplus construction materials, addressing the critical issue of waste influx in the industry. With 600 million tons of construction waste ending up in US landfills annually, Surplus aims to unlock the untapped potential of reusable materials, significantly reducing environmental impact while bolstering contractors' profitability. By providing a peer-to-peer marketplace connecting buyers and sellers in real-time, Surplus streamlines the process, allowing contractors to offload surplus materials efficiently.",
		},
		register: {
			title: 'Surplus | Sign up',
			description:
				'Surplus streamlines buying and selling unused building materials, allowing you to reduce construction waste and save money. Like Habitat for Humanity ReStore or OFFLOADIT but with contractors near me. Wood, concrete, stone, steel, brick, and more.',
			header: 'Sign up',
			labels: {
				email: 'Email',
				password: 'Password',
				confirmPassword: 'Confirm password',
				registerWithGoogle: 'Sign up with Google',
				alreadyHaveAccount: 'Already have an account?',
				alreadyHaveAccountLink: 'Sign in',
			},
			placeholders: {
				email: 'you@me.com',
				password: 'password',
				confirmPassword: 'password',
			},
			errors: {
				passwordsDontMatch: "Passwords don't match",
				accountExistsWithEmail: 'An account already exists with this emaiil',
			},
		},
		'password-reset': {
			title: 'Surplus | Password reset',
			description: 'Reset your password',
			header: 'Password reset',
			subheader: "We'll send you a one-time link to reset your password",
			content: 'Use this page to reset your password.',
			successSubtitle: 'If that is a valid email, we just sent you a reset code.',
			email: {
				title: 'Reset your password',
				subtitle: 'You requested a password reset',
				isEmailUnexpected: 'If you did not request a password reset, please ignore this email or',
				contactSupport: 'contact support',
				labels: {
					resetPasswordButton: 'Reset password',
				},
				resetPasswordButtonSubtitle: 'This link will expire in 2 hours.',
			},
			labels: {
				email: 'Email',
			},
			placeholders: {
				email: 'you@me.com',
			},
			token: {
				labels: {
					password: 'Password',
					confirmPassword: 'Confirm password',
				},
				placeholders: {
					password: 'password',
					confirmPassword: 'password',
				},
				errors: {
					expiredToken: 'This link has expired.',
				},
			},
		},
		'verify-email': {
			header: 'Verify your email',
			title: 'Surplus | Verify your email',
			description: 'Verify your email',
			subheader: 'Check your inbox for verification code sent to',
			signInWithDifferentAccount: 'Sign in with a different account',
			labels: {
				verificationCode: 'Code',
				requestNewCode: 'Send new code',
			},
			placeholders: {
				verificationCode: '******',
			},
			errors: {
				invalidVerificationCode: 'incorrect verification code...',
			},
		},
		cart: {
			title: 'Surplus | My truck',
			header: 'My truck',
			orderSummaryTitle: 'Order summary',
			description: "View items you've added to your truck.",
			labels: {
				total: 'Total',
				emptyCartTitle: 'Your truck is empty',
				emptyCartdescription: 'Items you add to your truck will appear here.',
				checkoutButton: 'Reserve supply',
				checkoutButtonDescription:
					'Reserving a supply will place a hold on the orders in your truck and kick off coordinating a delivery.',
			},
			errors: {
				noItemsInCart: 'No items in your truck',
			},
		},
		order: {
			title: 'Surplus | Orders',
			description: 'View all your orders.',
			header: 'Orders',
			yourOrdersHeader: 'Your orders',
			noYourOrdersDescription: "Orders you've placed will show up here.",
			ordersWithYourListingsHeader: 'Orders with your listings',
			noOrdersWithYourListingsDescription:
				'Orders others have placed that include your listings will show up here.',

			orderId: {
				title: 'Surplus | Order',
				description: 'View order details.',
				labels: {
					orderConfirmed: 'Order confirmed!',
					orderNumber: 'Order number',
					orderStatus: 'Order status',
				},
			},
		},
		listings: {
			title: 'Surplus | Listings',
			description:
				'Surplus streamlines buying and selling unused building materials, allowing you to reduce construction waste and save money. Like Habitat for Humanity ReStore or OFFLOADIT but with contractors near me. Wood, concrete, stone, steel, brick, and more.',
			labels: {
				filterBy: 'Only show',
				currentListingsTable: 'Current listings',
				newListingForm: 'Create a new listing',
				material: 'MATERIAL',
				photos: 'PHOTOS',
				quantity: 'QUANTITY',
				dimensions: 'DIMENSIONS',
				location: 'LOCATION',
				manufacturer: 'MANUFACTURER',
				price: 'PRICE',
				wood: 'WOOD',
				concrete: 'CONCRETE',
				stone: 'STONE',
				steel: 'STEEL',
				brick: 'BRICK',
				postNewListing: 'Post listing',
				lookingFor: 'Looking for',
				forSale: 'For sale',
				myListings: 'My listings',
				additionalInfo: 'ADDITIONAL INFORMATION',
				confirmDeleteListingTitle: 'Confirm',
				confirmDeleteListingBody: "Are you sure you'd like to delete this listing?",
				doNotOwnSupplyListing: 'This supply is not yours.',
				missingListingId: 'Missing supply id.',
				myLikes: 'Liked items',
			},
			placeholders: {
				quantity: 'eg. 45',
				dimensions: 'e.g. 2” x 4” x 10’',
				location: 'e.g. City, State',
				manufacturer: 'e.g. Lowes',
				price: 'e.g. $399',
				additionalInfo: 'eg. subcategory',
			},
			errors: {
				missingImageMetadata: "We're having trouble uploading your supply image. Please try again.",
				noListings: 'No listings',
				deleteListingPrefix: 'Error deleting item',
			},
		},
	},
	componenets: {
		DimensionCell: {
			labels: {
				feet: 'ft',
				inches: 'in',
				lengthShorthand: 'L',
				heightShorthand: 'H',
				widthShorthand: 'W',
			},
			placeholders: {
				dimension: '0.01',
			},
		},
	},
	buttons: {
		login: 'Sign in',
		save: 'Save',
		logout: 'Log out',
		register: 'Sign up',
		forgotPassword: 'Forgot password?',
		reset: 'Reset',
		setPassword: 'Set password',
		confirm: 'Confirm',
		goHome: 'Go home',
		edit: 'Edit',
		delete: 'Delete',
		yes: 'Yes',
		addToCart: 'Add to truck',
		addToCartToastConfirmation: 'Item added to cart',
		addedToCart: 'Remove from truck',
		like: 'Like',
		liked: 'Liked',
		myLikes: 'Liked items',
		myCart: 'My truck',
		myOrders: 'My orders',
		undo: 'Undo',
	},
	errors: {
		catchAll: 'Something went wrong. Try again.',
		unauthorized: 'Unauthorized to be here 🤨',
		s3Error: 'Error reaching S3',
		resendApiError: `We're having troubles sending emails right now. Please try again in a bit.`,
		missingSlugData: 'Missing slug data',
		maxOrdersReached: `You've reached the maximimum number of orders you can have at one time. You can only have ${MAX_ACTIVE_ORDERS} active orders at any given time. Reach out to us to coordinate further orders.`,
		screenWidthIsSmall: 'works better on desktop',
	},
	validation: {
		tooManySupplyListingImagesSelected: `Select up to ${MAX_NUMBER_SUPPLY_LISTING_IMAGES} images.`,
		required: 'This field is required.',
		oneImageRequired: 'At least one image is required.',
		unexpectedType: 'Unexpected value.',
		allOrNothingForDimensions: 'Complete all dimensions or leave all blank.',
	},
	emailTemplates: {
		resetPassword: {
			subject: '[Surplus] Reset your password',
		},
		verifyEmail: {
			subject: '[Surplus] Verify your email',
		},
		reserveSupplyTemplates: {
			admin: {
				subject: '[Surplus] An order was created',
			},
			seller: {
				subject: '[Surplus] Someone requested to purchase your supplies!',
			},
			buyer: {
				subject: "[Surplus] We've received your purchase request!",
			},
		},
	},
};
