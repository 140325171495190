export const STATUS_CODES = {
	success: 'SUCCESS',
	error: 'ERROR',
	failure: 'FAILURE',
};

export const SUPPLY_LISTING_POLL_LIMIT = 50;
export const MAX_XL_STRING_LENGTH = 250;
export const MAX_NUMBER_SUPPLY_LISTING_IMAGES = 6;
export const MAX_ACTIVE_ORDERS = 10;

export const MIN_SUPPORTED_SCREEN_WIDTH = 1200;

export const API_ENDPOINTS = {
	S3_PRESIGNED_POST: '/api/s3/presignedPost.json',
	SUPPLY_LISTING: '/api/supplyListing.json',
	SUPPLY_LISTING_ITEM: '/api/supplyListing/{{supplyListingId}}.json',
	USER_CART_ITEM: '/api/user/{{userId}}/cart/{{supplyListingId}}.json',
	USER_CART: '/api/user/{{userId}}/cart.json',
	USER_LIKES_ITEM: '/api/user/{{userId}}/likes/{{supplyListingId}}.json',
	USER_LIKES: '/api/user/{{userId}}/likes.json',
};
